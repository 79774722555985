<template>
  <div>
    <fractions-menu v-if="loggedIn"></fractions-menu>
    <router-view @login="doLogin"></router-view>
  </div>
</template>

<script>
import fractionsMenu from './menu.vue'

export default {
  data() {
    return {
      //"loggedIn": Auth.isLoggedIn()
      "loggedIn": false
    }
  },
  methods: {
    doLogin() {
      this.loggedIn = Auth.isLoggedIn();
    }
  },
  components: {
    fractionsMenu
  },
  created() {
    this.doLogin();
  }
    // empty
}
</script>
