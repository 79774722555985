
<template>
  <div style="text-align:center" :class="{ 'wait': waiting }">
    <div style="" class="p-5 container-flex">

      <div class="row mb-4">
        <div class="col-7 mx-auto">
          <h2>{{ $route.meta.title || title }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="bg-light mb-4 p-3 pt-4 col-3 mx-auto">
          <div class="form-group row">
            <label class="form-label col-4 col-form-label">Trade Date</label>
            <div class="col-8"><vue-datepicker class="form-control" v-model="attributes.trade_date"></vue-datepicker></div>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Product</label>
            <div class="col-8">
              <vue-multiselect class="form-control" v-model="attributes.product_id" :options="filtered_lists.product_id" @change="setValues('product_id')"></vue-multiselect>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Contract Month</label>
            <div class="col-8">
              <vue-multiselect class="form-control" v-model="attributes.contract_month" :options="fields.contract_month.list"></vue-multiselect>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Contract Year</label>
            <div class="col-8">
              <vue-multiselect class="form-control" v-model="attributes.contract_year" :options="fields.contract_year.list"></vue-multiselect>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label" for="show_all">Show All</label>
            <div class="col-8 text-left" style="vertical-align:middle">
              <input type="checkbox" class="" v-model="show_all" id="show_all"></input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button @click="fetchItems" class="btn btn-primary">Search</button>
            </div>
          </div>

        </div>
      </div>

      <div style="display:inline-block" v-show="items.length">
        <div class="row mt-0 mb-0">
          <export-button class="ml-auto mt-0 mb-0" :fields="fields" :records="items"></export-button>
        </div>
        <div class="row">
          <!-- to do: disable sort -->
          <fractions-grid class="mx-auto" item-height="40" row-click :fields="fields" :records="items" :actions="actions" @sort="sort" :showSelector="true"></fractions-grid>
        </div>
        <div class="row">
          <div class="ml-auto mt-3">
            <ul class="pagination" v-if="pagination.total_pages">
              <li v-bind:class="[{disabled: !pagination.previous_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.previous_page)">Previous</a></li>
              <li class="page-item disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.total_pages }}</a></li>
              <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.next_page)">Next</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {vueChart, vueGrid as fractionsGrid, vueDatepicker, vueMultiselect, exportButton} from 'vue-components'
  import * as library from 'js-library'

  export default {

    data() {
      return {
        name: 'cmeOptions',
        endpoint: this.$route.meta.endpoint,
        actions: [],
        pagination: {},
        items: [],
        attributes: {
          trade_date: library.$todayPlus(-1),
          product_id: [],
          contract_month: [],
          contract_year: [],
          product_group: [],
          sub_group: [],
          category: [],
          sub_category: []
        },
        fields: {
          date_begin: {},
          date_end: {},
          location: {}
        },
        waiting: false,
        sort_obj: {},
        show_all: 1
      }
    },
    created() {
      console.log(this.endpoint);
      this.fetchVM();
    },
    methods: {
      fetchVM() {
        let vm = this;
        fetch(this.endpoint,{method: 'OPTIONS'})
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            var to_get = ['form_data','search_data','showTotals','actions','enableExport','commands','tabs','fields','autoload'];

            for(var i in to_get) {
              var item = to_get[i];
              console.log(item, res[item]);
              if(typeof res[item] !== 'undefined') this[item] = res[item];
            }

            //if(this.autoload !== false) this.fetchItems();

          })
          .catch(err => console.log(err));
      },
      fetchItems() {
        this.waiting=true;
        fetch(this.endpoint + '?' + this.searchString)
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            if(typeof(res.data) != 'undefined') {
              this.items = res.data;
              this.pagination = res.pagination;
              this.waiting = false;
            }
            else {
              this.waiting = false;
              this.items = res;
            }
          })
          .catch(err => console.log(err));
      },
      fetchPage(page) {
        console.log('fetchpage: '+page);
        var search_obj = library.$copy(this.search_obj);
        search_obj['page'] = page;
        this.search_obj = search_obj;
        this.fetchItems();
      },
      sort(obj) {
        console.log('sort',obj);
        this.sort_obj = obj;
        this.fetchItems();
      },
      recordIndex(id) {
        let record = this.records.filter(function(record){ return record.id === id})[0];
        let index = this.records.indexOf(record);
        return index;
      },
      setValues(field) {
        var formvalue = this.attributes[field];  console.log(formvalue);

        if(this.lists[field]) var selectedoption = this.lists[field].filter(function(option){
          if(option.id && formvalue) {
            return option.id.toString() === formvalue.toString();
          }
          else return false;
        })[0];
        //else var selectedoption = { 'id' : formvalue, 'display' : formvalue};  // for checkboxes

        if(selectedoption) {
          for(var attribute in selectedoption) {
            var attributevalue = selectedoption[attribute];
            console.log(attribute,attributevalue);
            if(selectedoption.hasOwnProperty(attribute) && this.attributes.hasOwnProperty(attribute) && attribute !== 'id' && attribute !== 'display') {
              this.attributes[attribute] = attributevalue;
            }
          }
        }
        return;
      },
      setValuesAll() {
        for(var field in this.fields) {
          if(this.fields[field].list) {
            this.setValues(field);
          }
        }
      }
    },
    watch: {
      show_all(new_value) {
        console.log('show_all',new_value);
        if(new_value) {
          console.log('new_value',new_value);
          this.attributes.favorite = 0;
        }
        else {
          console.log('no_value',new_value);
          this.attributes.favorite = 1;
        }
        this.attributes = library.$copy(this.attributes);
      }
    },
    computed: {
      lists() {
        var output = {};
        for(var field in this.fields) {
          console.log(field, this.fields[field].format);
          if(this.fields[field].hasOwnProperty("list")) output[field] = this.fields[field].list;
        }
        return output;
      },
      filtered_lists() {
        var output = {};
        var that = this;
        for(var list in this.lists) {
          var listOptions = this.lists[list];
          var newlist = listOptions.filter(function(option){
            for(var optionAttribute in option) {
              if(option.hasOwnProperty(optionAttribute) && that.attributes.hasOwnProperty(optionAttribute) && optionAttribute !== 'id' && optionAttribute !== 'display') {
                var attributeValue = option[optionAttribute];
                var formValue = that.attributes[optionAttribute];
                if(typeof(formValue) == 'undefined' || formValue == "" || formValue == null) continue;
                if(typeof(attributeValue) == 'undefined' || attributeValue == "" || attributeValue == null) continue;
                if(formValue.toString() !== attributeValue.toString()) {
                  return false;  // only ever return false if there is a non-match
                }
              }
            }
            return true;  // after going through all attributes
          });
          output[list] = newlist;
        }
        return output;
      },
      searchString() {
        var search_obj = this.attributes || {};
        console.log('search_obj',search_obj);
        var searchstring = library.$objToHttp(search_obj);
        console.log('searchstring',searchstring);

        // var searchstring = '';
        // if(search_obj['date_begin']) searchstring += 'date=gte:' + search_obj['date_begin'] + '&';
        // if(search_obj['date_end']) searchstring += 'date=lte:' + search_obj['date_end'] + '&';
        // if(search_obj['location'].length) {
        //   for(var i=0;i<search_obj['location'].length;i++) {
        //     searchstring += 'location=' + search_obj['location'][i] + '&';
        //   }
        // }

        // sort
        if(this.sort_obj['sortField']) searchstring += 'sortField=' + this.sort_obj['sortField'] + '&';
        if(this.sort_obj['sortDir']) searchstring += 'sortDir=' + this.sort_obj['sortDir'] + '&';

        return searchstring;
      }
    },
    components: {
      fractionsGrid,
      vueDatepicker,
      vueChart,
      vueMultiselect,
      exportButton
    }
  }
</script>

<style scoped>
.wait {
  cursor: wait;
}
.top-right {
  position: absolute;
  top: 70px;
  right: 50px;
}
.shift-down {
  position: relative;
  top: 73px;
  right: 60px;
}
</style>
