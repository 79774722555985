
<template>
  <div style="text-align:center" :class="{ 'wait': waiting }">
    <div style="" class="p-5 container-flex">

      <div class="row mb-4">
        <div class="col-7 mx-auto">
          <h2>{{ $route.meta.title || title }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="bg-light mb-4 p-3 pt-4 col-3 mx-auto">
          <div class="form-group row">
            <label class="form-label col-4 col-form-label">Start Date</label>
            <div class="col-8"><vue-datepicker class="form-control" v-model="attributes.date_begin"></vue-datepicker></div>
          </div>

          <div class="form-group row">
            <label class="form-label col-4 col-form-label">End Date</label>
            <div class="col-8"><vue-datepicker class="form-control" v-model="attributes.date_end"></vue-datepicker></div>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Location</label>
            <div class="col-8">
              <vue-multiselect class="form-control" v-model="attributes.location" :options="fields.location.list"></vue-multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button @click="fetchItems" class="btn btn-primary">Search</button>
            </div>
          </div>

        </div>
      </div>

      <div style="display:inline-block" v-show="items.length">
        <div class="row mt-0 mb-0">
          <export-button class="ml-auto mt-0 mb-0" :fields="fields" :records="items"></export-button>
        </div>
        <div class="row" v-for="location,location_name in data_parsed">
          <!-- to do: disable sort -->
          <!-- <fractions-grid class="mx-auto" item-height="40" row-click :fields="fields" :records="recordsFormatted" :actions="actions" @sort="sort" :showSelector="true"></fractions-grid> -->
          <div class="card m-4" >
            <div class="card-header">
              <strong>{{ location_name }}</strong>
            </div>

              <table class="table">
                <tr>
                  <th v-for="(period,d) in location">{{ d.substring(5) }}</th>
                </tr>
                <tr>
                  <td v-for="(period,d) in location">{{ period.hi }}</td>
                </tr>
                <tr>
                  <td v-for="(period,d) in location">{{ period.lo }}</td>
                </tr>
              </table>

          </div>
        </div>
        <div class="row">
          <div class="ml-auto mt-3">
            <ul class="pagination" v-if="pagination.total_pages">
              <li v-bind:class="[{disabled: !pagination.previous_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.previous_page)">Previous</a></li>
              <li class="page-item disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.total_pages }}</a></li>
              <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item"><a class="page-link" href="#" @click="fetchPage(pagination.next_page)">Next</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {vueChart, vueGrid as fractionsGrid, vueDatepicker, vueMultiselect, exportButton} from 'vue-components'

  import * as library from 'js-library'

  export default {

    data() {
      return {
        name: 'weatherData',
        endpoint: this.$route.meta.endpoint,
        actions: [],
        pagination: {},
        items: [],
        attributes: {
          date_begin: library.$today(),
          date_end: library.$todayPlus(6),
          location: []
        },
        fields: {
          date_begin: {},
          date_end: {},
          location: {}
        },
        waiting: false,
        sort_obj: {}
      }
    },
    created() {
      console.log(this.endpoint);
      this.fetchVM();
    },
    methods: {
      fetchVM() {
        let vm = this;
        fetch(this.endpoint,{method: 'OPTIONS'})
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            var to_get = ['form_data','search_data','showTotals','actions','enableExport','commands','tabs','fields','autoload'];

            for(var i in to_get) {
              var item = to_get[i];
              console.log(item, res[item]);
              if(typeof res[item] !== 'undefined') this[item] = res[item];
            }

            this.fetchItems();

          })
          .catch(err => console.log(err));
      },
      fetchItems() {
        this.waiting=true;
        fetch(this.endpoint + '?' + this.searchString)
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            if(typeof(res.data) != 'undefined') {
              this.items = res.data;
              this.pagination = res.pagination;
              this.waiting = false;
            }
            else {
              this.waiting = false;
              this.items = res;
            }
          })
          .catch(err => console.log(err));
      },
      fetchPage(page) {
        this.waiting=true;
        fetch(this.endpoint + '?' + this.searchString + 'page=' + page)
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            if(typeof(res.data) != 'undefined') {
              this.items = res.data;
              this.pagination = res.pagination;
              this.waiting = false;
            }
            else {
              this.waiting = false;
              this.items = res;
            }
          })
          .catch(err => console.log(err));
      },
      sort(obj) {
        console.log('sort',obj);
        this.sort_obj = obj;
        this.fetchItems();
      },
      recordIndex(id) {
        let record = this.records.filter(function(record){ return record.id === id})[0];
        let index = this.records.indexOf(record);
        return index;
      },
      setValues(field) {
        console.log("setValues",field);

        var formvalue = this.attributes[field];  console.log(formvalue);

        if(this.lists[field]) var selectedoption = this.lists[field].filter(function(option){
          if(option.id && formvalue) {
            return option.id.toString() === formvalue.toString();
          }
          else return false;
        })[0];

        console.log(selectedoption);

        if(selectedoption) {
          for(var attribute in selectedoption) {
            var attributevalue = selectedoption[attribute];
            console.log(attribute,attributevalue);
            if(selectedoption.hasOwnProperty(attribute) && this.attributes.hasOwnProperty(attribute) && attribute !== 'id' && attribute !== 'display') {
              this.attributes[attribute] = attributevalue;
            }
          }
        }
        return;
      }
    },
    computed: {
      recordsFormatted(state,getters) {
        var that = this;
        if(!this.items) return {};
        return this.items.map(function(row){
          var output = {};
          var result = null;
          for(var field in row) {
            if(field in that.fields && that.fields[field].format !== 'hide') {
              var data = row[field];
              result = data;
              if(typeof(data) == 'undefined' || data === '') result = "";
              if(typeof(field) == 'undefined') result = "";
              var format = that.fields[field].format;

              if(format == 'list' && !isNaN(data)) {
                let list = that.fields[field].list;
                let obj = list.filter(function (record) { return record.id === data })[0];
                if(obj) result = obj['display'];  /*** was throwing error if data point was not in list..could happen if something is deleted ***/
                else result = '';  // so it shows blanks not 0's.
              }
              else result = library.$format(data, format);

              output[field] = result;
            }
            else if(['clicked', 'editing', 'saved', 'status'].includes(field)) output[field] = row[field];
          }
          return output;
        });
      },
      data_parsed() {
        var output = {};
        for(var r in this.recordsFormatted) {
          var row = this.recordsFormatted[r];
          console.log(row.date);
          var type = 'hi';
          if(row.daytime == 0) type = 'lo';
          if(typeof(output[row.location]) == 'undefined') output[row.location] = {};
          if(typeof(output[row.location][row.date]) == 'undefined') output[row.location][row.date] = {};
          output[row.location][row.date][type] = row.temperature;
        }
        return output;
      },
      lists() {
        var output = {};
        for(var field in this.fields) {
          console.log(field, this.fields[field].format);
          if(this.fields[field].hasOwnProperty("list")) output[field] = this.fields[field].list;
        }
        return output;
      },
      searchString() {
        var search_obj = this.attributes || {};
        console.log('search_obj',search_obj);
        // var searchString = library.$objToHttp(search_obj);
        // console.log('searchString',searchString);

        var searchstring = '';
        if(search_obj['date_begin']) searchstring += 'date=gte:' + search_obj['date_begin'] + '&';
        if(search_obj['date_end']) searchstring += 'date=lte:' + search_obj['date_end'] + '&';
        if(search_obj['location'].length) {
          for(var i=0;i<search_obj['location'].length;i++) {
            searchstring += 'location=' + search_obj['location'][i] + '&';
          }
        }


        // sort
        if(this.sort_obj['sortField']) searchstring += 'sortField=' + this.sort_obj['sortField'] + '&';
        if(this.sort_obj['sortDir']) searchstring += 'sortDir=' + this.sort_obj['sortDir'] + '&';

        return searchstring;
      }
    },
    components: {
      fractionsGrid,
      vueDatepicker,
      vueChart,
      vueMultiselect,
      exportButton
    }
  }
</script>

<style scoped>
.wait {
  cursor: wait;
}
.top-right {
  position: absolute;
  top: 70px;
  right: 50px;
}
.shift-down {
  position: relative;
  top: 73px;
  right: 60px;
}
</style>
