
// import vue
import Vue from 'vue'
import VueRouter from 'vue-router'
//import Vuex from 'vuex'

// assets
import logo from './../images/logo.png'
import bootstrap from 'bootstrap/dist/css/bootstrap';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
//import classes from './../css/main.css';

// app
import App from './components/App-router.vue'
//import { store } from './store'

// components
import menu from './components/menu.vue'

// views
import {addRole} from 'vue-libs'
import {addUser} from 'vue-libs'
import {changePassword} from 'vue-libs'
import {editRole} from 'vue-libs'
import {editUser} from 'vue-libs'
import {dashboard} from 'vue-libs'
import {history} from 'vue-libs'
import {importFile} from 'vue-libs'
import {lockout} from 'vue-libs'
import {login} from 'vue-libs'
import {logout} from 'vue-libs'
import {multicube} from 'vue-libs'
import {multichart} from 'vue-libs'
import {uploadFile} from 'vue-libs'
import {updateUser} from 'vue-libs'
import {user_roles} from 'vue-libs'
import {users} from 'vue-libs'

// external views
import {gridView} from 'vue-components'
import {cubeView} from 'vue-components'

// domain views
import gdaIndices from './views/gdaIndices.vue'
import cmeFutures from './views/cmeFutures.vue'
import cmeOptions from './views/cmeOptions.vue'
import cmeWeather from './views/cmeWeather.vue'
import weatherGovData from './views/weatherGovData.vue'
import weatherbitForecast from './views/weatherbitForecast.vue'


// set up vue
Vue.use(VueRouter)
//Vue.use(Vuex)
//Vue.component('navbar', require('./components/Navbar.vue'));

const router = new VueRouter({
  mode: 'history',
  //base: __dirname,
  base: '/',
  routes: [

    // common
    {path: '', component: dashboard, meta: { title: 'Fractions Accounting', endpoint: '/api/dashboard', requireLogin: true }},
    {path: '/', component: dashboard, meta: { title: 'Fractions Accounting', endpoint: '/api/dashboard', requireLogin: true }},
    {path: '/login', component: login, meta: { logo: logo }},
    {path: '/logout', component: logout},

    {path: '/roles', component: gridView, meta: { title: 'Roles', endpoint: '/api/roles', requireLogin: true }},
    {path: '/roles/new', component: addRole, meta: { title: 'New Role', requireLogin: true }},
    {path: '/roles/new/:id', component: addRole, meta: { title: 'New Role', requireLogin: true }},
    {path: '/roles/edit/:id', component: editRole, meta: { title: 'Edit Role', requireLogin: true }},

    {path: '/users', component: users, meta: { title: 'Users', endpoint: '/api/users', requireLogin: true }},
    {path: '/users/new', component: addUser, meta: { title: 'Add User', requireLogin: true }},
    {path: '/users/new/:id', component: addUser, meta: { title: 'Add User', requireLogin: true }},
    {path: '/users/edit/:id', component: editUser, meta: { title: 'Edit User', requireLogin: true }},
    {path: '/user/:id/roles', component: user_roles, meta: { title: 'User Roles', requireLogin: true }},

    {path: '/uploadFile', component: uploadFile, meta: { title: 'Upload File', requireLogin: true }},
    {path: '/importFile/:id', component: importFile, meta: { title: 'Import File', requireLogin: true }},

    {path: '/lockout', component: lockout, meta: { title: 'Admin Lockout Date', requireLogin: true }},
    {path: '/uploads', component: gridView, meta: { title: 'Uploads', endpoint: '/api/uploads', requireLogin: true }},
    {path: '/imports', component: gridView, meta: { title: 'Imports', endpoint: '/api/imports', requireLogin: true }},

    {path: '/multicube', component: multicube, meta: { title: 'Multi-Cube!!', endpoint: '/api/cubes', requireLogin: true }},
    {path: '/multichart', component: multichart, meta: { title: 'Multi-Chart!!', endpoint: '/api/cubes', requireLogin: true }},

    {path: '/changePassword', component: changePassword, meta: { title: 'Update Password', endpoint: '/api/users', requireLogin: true }},
    {path: '/updateUser', component: updateUser, meta: { title: 'Update My Information', endpoint: '/api/users', requireLogin: true }},

    // domain s pecific
    {path: '/cmeFutures', component: cmeFutures, meta: { title: 'CME Futures', endpoint: '/api/cmeFutures', requireLogin: true }},
    {path: '/gdaIndices', component: gdaIndices, meta: { title: 'Platts Gas Daily Indices', endpoint: '/api/gdaIndices', requireLogin: true }},
    {path: '/gdaPreliminaryIndices', component: gdaIndices, meta: { title: 'Platts Gas Daily Preliminary Indices', endpoint: '/api/gdaPreliminaryIndices', requireLogin: true }},
    {path: '/gdaProducts', component: gridView, meta: { title: 'Platts Gas Daily Locations', endpoint: '/api/gdaProducts', requireLogin: true }},
    {path: '/cmeWeather', component: cmeWeather, meta: { title: 'CME Weather Futures', endpoint: '/api/weatherFutures', requireLogin: true }},
    {path: '/weatherForecast', component: weatherGovData, meta: { title: 'Weather.gov Forecasts', endpoint: '/api/weatherForecast', requireLogin: true }},
    {path: '/cmeOptions', component: cmeOptions, meta: { title: 'Nymex Options Settlements', endpoint: '/api/cmeOptions', requireLogin: true }},
    {path: '/gdaIndicesDetail', component: cubeView, meta: { title: 'GDA Indices Cube', endpoint: '/api/gdaIndicesDetail', requireLogin: true }},
    {path: '/weatherbitForecast', component: weatherbitForecast, meta: { title: 'Latest Weatherbit Forecast', endpoint: '/api/weatherbitForecast', requireLogin: true }},

  ]
})

window.Auth = {

  isLoggedIn() {
    var user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    if(typeof(user) == 'undefined' || user == null) return false; // not even logged in
    return true;
  },
  logOut() {
    localStorage.removeItem("user");
  },
  logIn(user) {
    localStorage.setItem("user",JSON.stringify(user));
  },
  user() {
    var user = JSON.parse(localStorage.getItem("user"));
    //console.log(user);
    if(typeof(user) == 'undefined' || user == null) return false; // not even logged in
    return user;
  },
  is(role) {
    var user = JSON.parse(localStorage.getItem("user"));

    if(typeof(user) == 'undefined' || user == null) return false; // not even logged in

    if(typeof(user.roles) == 'undefined' || user.roles.length == 0) return false;  // user has no roles

    if(typeof(role) == 'string') return user.roles.filter(function(item){
      return item.role == role;
    }).length;  // only matching one role

    if(typeof(role) == 'array') return user.roles.filter(function(item){
      return role.indexOf(item) != -1;
    }).length;  // only matching one role

    return false;
  }
}

// this lets you use Auth inside templates
Vue.prototype.Auth = window.Auth;

// this lets you use window inside templates
//Vue.prototype.window = window;

router.beforeEach((to, from, next) => {
  //console.log([to,from,next]);
  if(to.meta.requireLogin && !Auth.isLoggedIn()) {
    console.log("login required, redirecting");
    router.replace({path: '/login', query: { to: to.path }});
  }
  if(to.meta.requireRole && !Auth.is(to.meta.requireRole)) {
    console.log("login required, redirecting");
    router.replace({path: '/login', query: { to: to.path }});
  }

  next();
})

router.afterEach((to, from) => {
    document.title = to.meta.title || "Fractions";
});

const app = new Vue({
    //store,
    router,
    render: createEle => createEle(App)
}).$mount('#app');

window.checkResponse = function(response) {

    if(response.status == 403) {
      console.log("login required, redirecting");
      window.Auth.logOut();
      console.log(JSON.parse(localStorage.getItem("user")));
      router.replace({path: '/login', query: { to: router.history.current.path }});
    }
    else return response;
}

// this lets you use Auth inside templates
Vue.prototype.checkResponse = window.checkResponse;
